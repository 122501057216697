import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {environment} from '../../environments/environment';
import {Token} from '../models/token';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as jwt_decode from 'jwt-decode';
import {TokenPayload} from '../models/token-payload';
import {UserCredentials} from '../models/user-credentials';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly handleError: HandleError;
  private currentUserSubject: BehaviorSubject<Token>;
  // private currentUser: Observable<Token>;
  private currentUser: Token;

  private configUrl = 'assets/config.json';

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Token {
    return this.currentUserSubject.value;
  }
  public getTokenPayload(): TokenPayload {

    const token =  this.currentUserSubject.value.access_token;

    const decoded = jwt_decode(token);

    return decoded;
  }
  loginAuth(userCredentials: UserCredentials): Observable<any> {

    return this.http.post<any>(environment.serverUrl + '/shark/auth', userCredentials);
  }
  login(username: string, password: string): Observable<any> {

    const body = 'grant_type=password&username=' + username + '&password=' + password;
    // console.info(body);
    return this.http.post<any>(environment.serverUrl + '/shark/login', body)
      .pipe(map(token => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // console.info(token);

        localStorage.setItem('currentUser', JSON.stringify(token));
        this.currentUserSubject.next(token);
        this.setSession(token);

        return token;
      }));
  }
  private setSession(token: Token): void {
    const expiresAt = moment().add(token.expires_in, 'second');
    localStorage.setItem('access_token', token.access_token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

    const helper = new JwtHelperService();
    const claim = helper.decodeToken(token.access_token);

    localStorage.setItem('access_role', claim['role']);

  }
  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
  }
  public isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getExpiration(): moment.Moment {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
  isAuthenticated(): boolean {
    return this.isLoggedIn();
  }
  getAccessRole(): string {

    return localStorage.getItem('access_role');
  }
  isAdmin(): boolean {
    return localStorage.getItem('access_role') === '0406ADM';
  }
  // isApiUser(): boolean {
  //   return localStorage.getItem('access_role') === '0406API';
  // }
  // isTNSUser(): boolean {
  //   return localStorage.getItem('access_role') === '0406TNS';
  // }

  // isAuthenticated(): boolean {
  //   const token = localStorage.getItem('access_token');
  //   // Check whether the token is expired and return
  //   // true or false
  //   return !this.jwtHelper.isTokenExpired(token);
  // }
}
