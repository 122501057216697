import { NgModule } from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import { CommonModule } from '@angular/common';
import {ExtensibleTableComponent} from './material/extensible-table/extensible-table.component';

const routes: Routes = [
  {
    path: 'eagle/pages',
    loadChildren: () => import('./pages/pages.module')
      .then (m => m.PagesModule)
  },
  {
    path: 'eagle/auth',
    loadChildren: () => import('./open/open.module')
      .then (m => m.OpenModule)
  },
  // {
  //   path: 'eagle/table',
  //   component: ExtensibleTableComponent,
  // },
  {path: '', redirectTo: 'eagle/auth/login', pathMatch: 'full' },
  {path: '**', redirectTo: 'eagle/auth/login' },
  // {path: 'eagle', redirectTo: 'eagle/login', pathMatch: 'full' },
  // {path: 'eagle/users', component: UsersComponent},
  // {path: 'eagle/login', component: LoginComponent},
  // {path: 'eagle/signup', component: SignupComponent},
  // {path: 'eagle/profile', component: ProfileComponent},

  // {
  //   path: 'eagle/auth',
  //   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  // }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, config)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
