import {Component, HostListener, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'api-auth-eagle';

  opened = true;
  // @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(public authService: AuthService, private router: Router) {

  }
  ngOnInit(): void {
  //   console.log(window.innerWidth);
  //   if (window.innerWidth < 768) {
  //     this.sidenav.fixedTopGap = 55;
  //     this.opened = false;
  //   } else {
  //     this.sidenav.fixedTopGap = 55;
  //     this.opened = true;
  //   }
  //   if (!this.isAuthenticated()){
  //     this.opened = false;
  //   }
  }
  //
  // @HostListener('window:resize', ['$event'])
  // onResize(event): void {
  //   if (event.target.innerWidth < 768) {
  //     this.sidenav.fixedTopGap = 55;
  //     this.opened = false;
  //   } else {
  //     this.sidenav.fixedTopGap = 55;
  //     this.opened = true;
  //   }
  //   if (!this.isAuthenticated()){
  //     this.opened = false;
  //   }
  // }
  //
  // isBiggerScreen(): boolean {
  //   const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  //   if (width < 768) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // isAuthenticated(): boolean {
  //   return this.authService.isAuthenticated();
  // }
  // logout(): void {
  //
  //   this.authService.logout();
  //   this.router.navigateByUrl('/eagle/login');
  //   this.opened = false;
  // }

}
